var fscb = window.fscb || {};

fscb.commons = (function() {

	var self = {};

	self.init = function(){
		scrollTo();
		setTtooltip();
		setPopover();
		setCustomCheck();
		fscb.toggleSystem();
		fscb.utils.checkLikeRadio('.radio-like');
		fscb.utils.smartmail('.smartmail');
		fscb.system.autocomplete('.autocomplete');
	};

	function setTtooltip(){
		$('[data-toggle="tooltip"]').tooltip();
	}

	function setPopover(){
		$('[data-toggle="popover"]:not([data-id])').popover();
	}

	function scrollTo(){
		$('.toScroll').on('click',function(e){
			e.preventDefault();
			var target = $(this).attr('href');
			$('html, body').animate({ scrollTop: $(target).offset().top - 80 }, 'slow');
		});
	}

	function setCustomCheck(){
		/**
		 * funziona con classe 'custom-check data all'input. definizioni css in visaul-check.less
		**/
		$('input.custom-check').each(function(){
			var $i = $(this);
			if(!$i.next().hasClass('visual')){
				var $visual = $('<div/>').addClass('visual');
				$i.after($visual);
			}
		})
	}

	self.expandSearch = function(){
		$btn = $('#expand-search-cta');
		$fieldset = $('#expand-fieldset');
		var bridge = '';
		$btn.on('click',function(){
			bridge = '';
			bridge = $btn.text();
			$btn.text($btn.attr('data-alt'));
			$btn.attr('data-alt',bridge);
			$fieldset.toggleClass('hidden');
		})
	}

	return self;

})();