var fscb = window.fscb || {};

fscb.utils = (function() {

	var self = {};

	self.checkLikeRadio = function(selector){
		if ($(selector).length > 0){
			$(selector).on('change',function() {
				$(selector+'[name="'+this.name+'"]').filter(':checked').not(this).removeAttr('checked');
			});
		}
	};

	self.smartmail = function(selector){
		/**
		 * es. <a class="smartmail" data-name="info" data-dom="wipitalia.it" href="javascript:void(0);">info @ wipitalia.it</a>
		 */
		$('body').on('click', selector, function(e) {
			var name = $(this).data('name');
			var domain = $(this).data('dom');
			var url = 'mailto:'+name+'@'+domain;
			window.open(url,"_top");
		});
	};

	self.getQueryVariable = function(variable){
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return pair[1];}
		}
		return(false);
	};

	self.filterList = function(triggerGroup, list){
		//kisses to  http://nexts.github.io/Jets.js/
		var style;
		$(triggerGroup + ' [data-filtering]').on('click',function(){

			$(triggerGroup + ' [data-filtering]').removeClass('active');
			if(style){style.remove()}
				$(this).addClass('active');
			if($(this).data('filtering')!==""){

				style = $('<style id="flt_style">'+list+' > *:not([data-filter="'+$(this).data('filtering')+'"]){display:none!important};</style>').insertBefore(list);
			}
		});
	};

	self.relationField = function(){
		/**
		 * PER MOSTRARE/NASCONDERE BLOCCHI DI FORM A SECONDA DELLA VOCE DI SELECT/RADIO SELEZIONATA
		 *
		 * select/radio -> [data-relation="NOME_GRUPPO"]
		 * contenuto da mostrare/nascondere: [data-relationgroup="NOME_GRUPPO"][data-related="OPTION_SELECTED"]
		 * Posso aggiungere più valori data-related, separati da spazio semplice
		 *
		 */
		var _group, _target;
		//modifica senza il change finale
		$('[ data-relationgroup]').hide();
		$('body').on('change','[data-relation]',function(){
			_group = $(this).data('relation');
			_target = $(this).val();
			$('[data-relationgroup="'+_group+'"]:not([data-related*="'+_target+'"])').hide().addClass('novalidate');
			$('[data-relationgroup="'+_group+'"][data-related*="'+_target+'"]').show().removeClass('novalidate');
		});
		$('[data-relation]').change();
	};


	self.lowercaseControl = function(selector){
		$(selector).on('blur',function(){
			var newstring = $(this).val().toLowerCase();
			$(this).val(newstring);
		});
	};

	self.uppercaseControl = function(selector){
		$(selector).on('blur',function(){
			var newstring = $(this).val().toUpperCase();
			$(this).val(newstring);
		});
	};

	self.capitalizeControl = function(selector){
		$(selector).on('blur',function(){
			var strtest = $(this).val();
			if (!strtest.replace(/\s/g, '').length) {
				$(this).val('');
			}else{
				var newstring = $(this).val().toLowerCase();
				var arr = newstring.split(' ');
				newstring = "";
				$.each(arr,function(i){
					newstring+=arr[i].substring(0,1).toUpperCase()+arr[i].substring(1)+' ';
				});
				newstring= newstring.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
				$(this).val(newstring);
			}
		});
	}

	self.getBrowserDimensions = function() {
		return {
			width: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
			height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
		};
	};

	self.isTouch = function() {
		return 'ontouchstart' in window        // works on most browsers
			|| navigator.maxTouchPoints;       // works on IE10/11 and Surface
	};

	self.isBreakPoint = function(options) {
		var obj = {};
		obj.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		if (fscb.breakpoints !== undefined){
			obj.bps = fscb.breakpoints;
		}else{
			obj.bps = {
				xs:500,
				sm:768,
				md:1024,
				lg:1280
			};
		}

		if (options === undefined || options.bp === undefined) {
			console.error('fscb.utils.isBreakPoint function needs a breakpoint value');
			return false;
		}else{
			obj.bp = options.bp;
		}

		if (options.bps !== undefined) {
			obj.bps.xs = options.bps.xs === undefined ? obj.bps.xs : options.bps.xs;
			obj.bps.sm = options.bps.sm === undefined ? obj.bps.sm : options.bps.sm;
			obj.bps.md = options.bps.md === undefined ? obj.bps.md : options.bps.md;
			obj.bps.lg = options.bps.lg === undefined ? obj.bps.lg : options.bps.lg;
		}

		return obj.w < obj.bps[obj.bp];
	}

	/* ----------------- public methods ---------------*/

	return self;

})(jQuery);