var fscb = window.fscb || {};

fscb.toggleSystem = (function ($) {

	var self = function(options){

		if (typeof options === "undefined") {
			options = {};
		}
		if (typeof options.selector === "undefined") {
			options.selector = '[data-toggleclass]';
		}

		$(options.selector).on('click',function(e){

			var $s = $(this);

			if (typeof $s.data('toggleclass') === "undefined") {
				if (typeof options.class === "undefined") {
					options.class = 'active';
				}
			}else{
				options.class = $s.data('toggleclass');
			}

			if (typeof $s.data('toggletarget') === "undefined") {
				if (typeof options.target === "undefined") {
					options.target = $s;
				}else{
					if(typeof options.target === "string"){
						options.target = $(options.target);
					}
				}
			}else{
				options.target = $($s.data('toggletarget'));
			}

			if (options.target.hasClass(options.class)) {
				options.target.removeClass(options.class);
			}else{
				options.target.addClass(options.class);
			}
		});
	};

	/**/
	return self;

})(jQuery);