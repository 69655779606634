var fscb = window.fscb || {};

fscb.system = (function ($) {

	var self = {};

	// funzione per estendere il menu di ricerca libri nella pagina di visualizzazione del catalogo
	self.addFieldsToSearchForm = function(ed,sez,num,vol,cp,d1,d2,inv) {
		document.getElementById("buttonPlus").innerHTML="<button type=\"button\" onClick=\"removeFieldsFromSearchForm()\">-</button>";
		var x = document.getElementById("searchForm").insertRow(4);
		var y = x.insertCell(0);
		var z = x.insertCell(1);
		y.innerHTML="Casa editrice";
		z.innerHTML="<input type='text' name='editore' size='30' value='"+ed+"'/>";
		var x = document.getElementById("searchForm").insertRow(5);
		var y = x.insertCell(0);
		var z = x.insertCell(1);
		y.innerHTML="Collocazione";
		z.innerHTML="";
		var x = document.getElementById("searchForm").insertRow(6);
		var y = x.insertCell(0);
		var z = x.insertCell(1);
		y.innerHTML="";
		z.innerHTML="<table><tr><td>Sezione:</td><td><input type='text' name='sezione' size='6' value='"+sez+"'/></td></tr><tr><td>Numero:</td><td><input type='text' name='numero' size='6' value='"+num+"'/></td></tr><tr><td>Volume</td><td><input type='text' name='volume' size='6' value='"+vol+"'/></td></tr><tr><td>Copia:</td><td><input type='text' name='copia' size='6' value='"+cp+"'/></td></tr></table>";
		var x = document.getElementById("searchForm").insertRow(7);
		var y = x.insertCell(0);
		var z = x.insertCell(1);
		y.innerHTML="Anno(min / max)";
		z.innerHTML="<input type='text' name='dataDa' size='6' value='"+d1+"'/> / <input type='text' name='dataA' size='6' value='"+d2+"'/>";
		var x = document.getElementById("searchForm").insertRow(8);
		var y = x.insertCell(0);
		var z = x.insertCell(1);
		y.innerHTML="Numero inventario";
		z.innerHTML="<input type='text' name='inv' size='30' value='"+inv+"'/>";
	}

	//funzione per rimuovere i campi supplementari della ricerca libri nella pagina di visualizzazione del catalogo
	self.removeFieldsFromSearchForm = function() {
		document.getElementById("searchForm").deleteRow(4);
		document.getElementById("searchForm").deleteRow(4);
		document.getElementById("searchForm").deleteRow(4);
		document.getElementById("searchForm").deleteRow(4);
		document.getElementById("searchForm").deleteRow(4);
		document.getElementById("buttonPlus").innerHTML="<button type=\"button\" onClick=\"addFieldsToSearchForm('','','','','','','','')\">+</button>";
	}

// funzione di verifica che il campo 'titolo' del nuovo libro non sia vuoto
	self.validateNewBook = function() {
		var x = document.forms["insertBookForm"]["titolo"].value;
		if (x=="") {
			alert("Specificare un titolo per il nuovo libro.");
			return false;
		}
		return true;
	}

	// funzione di verifica che il numero di inventario inserito dall'utente non sia già in uso nel catalogo
	self.checkInv = function() {
		var inv = document.forms["insertBookForm"]["inventario"].value;
		if (inv !== '') {
			$.post('classes/checkDuplicate.php', {num:inv}, function(data) {
				if(data=="true"){
					alert("Il numero di inventario indicato è già in uso.");
				}
			});
		}
	}

	// funzione di verifica che i campi 'nome', 'cognome' e 'password' del nuovo utente non siano vuoti
	self.validateNewUser = function() {
		var x = document.forms["insertUserForm"]["nome"].value;
		if (x=="") {
			alert("Inserire il nome del nuovo utente.");
			return false;
		}
		var x = document.forms["insertUserForm"]["cognome"].value;
		if (x=="") {
			alert("Inserire il cognome del nuovo utente.");
			return false;
		}
		var x = document.forms["insertUserForm"]["password"].value;
		if (x=="") {
			alert("Indicare una password per il nuovo utente.");
			return false;
		}
		return true;
	}

	// funzione di visualizzazione della password nella scheda utenti
	self.showPwd = function(pwd) {
		document.getElementById("pwd").innerHTML="<span class='label label-info'>"+pwd+"</span>  <a href=\"javascript:fscb.system.hidePwd('"+pwd+"')\"><i>nascondi</i></a>";
	}
	// funzione per nascondere nuovamente la password nella scheda utenti
	self.hidePwd = function(pwd) {
		document.getElementById("pwd").innerHTML="<a href=\"javascript:fscb.system.showPwd('"+pwd+"')\"><i>visualizza</i></a>";
	}
	self.validatePwd = function(pwd) {
		var x = document.forms["modifyPwd"]["new1"].value;
		var y = document.forms["modifyPwd"]["new2"].value;
		if (x != y) {
			alert("Le due ripetizioni della nuova password non corrispondono.");
			return false;
		}
		var z = document.forms["modifyPwd"]["old"].value;
		if (z != pwd) {
			alert("La vecchia password inserita non è corretta.");
			return false;
		}
		return true;
	}

	self.autocomplete = function(){
		var $o = $('[data-provide="typeahead"]');
		var srv = $o.data('service');
		$.get(srv, function(data){
			//console.dir(data);
			$o.typeahead({
				source:data,
				minLength: 2
			});
		},'json');
		$o.change(function() {
			var current = $o.typeahead("getActive");
			if (current) {
				$('#cod').val(current.cod).change();
				console.log($('#cod'));
			}
		});
	}

	/**/
	return self;

})(jQuery);